import axios from 'axios'
import { endpoints } from '../config'
import { getApiClient } from './api-client'
import mockUserInfo from './mockdata/mockdata-user.json'
import dayjs from 'dayjs'
import { EmployeeDataModelType } from '../common-types'

export interface EmployeeType {
  EmployeeId: string
  ADUserId?: string
  ADName?: string
  FirstName: string
  LastName: string
  FullName?: string
  Title?: string
  Department: {
    DepartmentName?: string
    DepartmentId?: string
    DivisionId?: string
    Division: {
      DivisionId?: string
      DivisionCode?: string
      DivisionName?: string
      TeamId?: string
      Role?: {
        RoleName?: string
      }
    }
  }
  DepartmentId?: boolean
  Location?: string
  Phone?: string
  Email?: string
  RequestTixAvaliability?: string
  ModifiedBy?: string
  ModifiedDate?: string
  CreatedBy?: string
  CreatedDate?: string
  EmployeeStatus?: string
  CompanyCode?: string
  IsSeniorExecutive?: boolean
  IsVipUser?: boolean
  AlwaysAllowsComps?: boolean
  FullPartTime?: string
  RequestedForPCTeam?: string[]
  PurposeTypeList?: string[]
  EmployeeDataModel?: EmployeeDataModelType
  UnionCode?: string
  IsValid?: number
  RequestedFor?: string
  RequestTixAvailability?: string
}

type GetEmployeeType = EmployeeType[]

export async function getMockUserInfo() {
  return mockUserInfo
}

/* export async function getUserInfo({ userEmail = '' } = {}) {
  const params = {
    email: userEmail,
  }

  return getApiClient({ endpoint: endpoints.userInfo, params })
} */

export async function getUserInfo(userToken: string) {
  const params = {
    email: userToken,
  }
  console.log(
    process.env.NEXT_PUBLIC_API_ROOT_URI,
    `${process.env.NEXT_PUBLIC_API_ROOT_URI}/users/users-info`,
    userToken
  )
  return axios
    .get(`${process.env.NEXT_PUBLIC_API_ROOT_URI}/users/users-info`, { params })
    .then((data) => data?.data)
}

export async function getUserAllocation({ requesteeId = '', seasonDate = '' } = {}) {
  const params = {
    requestedForId: requesteeId,
    seasonDate,
    userCurrentTime: dayjs(new Date()).format('MM/DD//YYYY, h:mm:ss A'),
  }

  return getApiClient({ endpoint: endpoints.userAllocation, params })
}

export async function getEmployees({ searchName = '' }): Promise<GetEmployeeType> {
  const params = {
    searchName,
  }

  return getApiClient({ endpoint: endpoints.getEmployees, params })
}

export async function getEmployeeById({ employeeId = '' }): Promise<EmployeeType> {
  const params = {
    employeeId,
  }

  return getApiClient({ endpoint: endpoints.getEmployeeById, params })
}
