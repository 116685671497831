import axios from 'axios'
import { endpoints } from '../config'
import { getApiClient, postApiClient } from './api-client'
import authSignerData from './mockdata/mockdata-auth-signer.json'
import pricingData from './mockdata/mockdata-pricing.json'
import eventData from './mockdata/mockdata.json'
import { SwapOptionObject } from '../app/tickets/components/swap-event-menu'
import dayjs from 'dayjs'
import { DATETIME_UTC_FORMAT } from '../utils'

export async function getMockEventsData() {
  return eventData
}
export async function getEvents({
  date = '',
  eventName = '',
  venueName = '',
  eventStatus = '',
  page = 1,
  pageSize = 15,
} = {}) {
  const params = {
    page,
    pageSize,
    date,
    eventName,
    venueName,
    eventStatus,
    eventId: '',
    browserCurrentTime: dayjs(new Date()).format(),
  }
  return axios.get(`/api/events`, { params }).then((data) => data?.data)
}

// export async function getAllEvents({
//   date = '',
//   eventName = '',
//   venueName = '',
//   eventStatus = '',
//   page = 1,
//   pageSize = 15,
// } = {}) {
//   const params = {
//     pageObject: JSON.stringify({
//       skip: '0',
//       take: pageSize,
//       page,
//       pageSize,
//     }),
//     date,
//     eventName,
//     venueName,
//     eventStatus,
//     eventId: '',
//   }
//   return getApiClient({ endpoint: endpoints.getEvents, params })
// }

export async function getEventSearch({
  searchInput,
  yearFilter,
}: {
  searchInput: string | ''
  yearFilter?: boolean
}) {
  // const noSpecialCharsRegex = /^\w+$/
  const params = {
    search: searchInput || '',
    searchDate: '',
    yearFilter,
  }

  return getApiClient({ endpoint: endpoints.search, params }).catch(() => [])
}

export async function getEventDetail({ eventId, employeeId }: { eventId: string; employeeId: string }) {
  const params = {
    eventId,
    employeeId,
  }
  return getApiClient({ endpoint: endpoints.getEventDetail, params })
}

export async function readSwapEvents({
  eventName,
  page,
  pageSize,
}: {
  eventName: string
  page?: string
  pageSize?: string
}): Promise<SwapOptionObject> {
  const params = {
    eventName,
    browserCurrentTime: dayjs(new Date()).format(DATETIME_UTC_FORMAT),
    page,
    pageSize,
  }
  return getApiClient({ endpoint: endpoints.readSwapEvents, params }).catch(() => [])
}

export async function getMockPricingData() {
  return pricingData
}

export async function getMockSignerData() {
  return authSignerData
}

export async function submitEventTicketRequest(payload: any, navigatedFrom: string) {
  const params = { navigatedFrom }
  return postApiClient({ endpoint: endpoints.submitTicketRequest, payload, params })
}
